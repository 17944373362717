<template>
  <div class="historyUsers-setting">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale === 'fr'
            ? "Gestion des Historiques de l'utilisateur"
            : 'User History Management'
        }}
      </div>
    </div>
    <div class="block-menu-users mt-2">
      <div class="list-tabs">
        <v-tabs
          bg-color="#5C2DD3"
          :hide-slider="false"
          slider-color="#5C2DD3"
          color="#5C2DD3"
          item-color="#5C2DD3"
          v-model="model"
        >
          <v-tab
            href="#tab-0"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('miseAjour', 'tab-0')"
          >
            <div class="menu-item">
              {{ $i18n.locale === 'fr' ? 'Tous' : 'All' }}
            </div>
          </v-tab>
          <v-tab
            href="#tab-1"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('projet', 'tab-1')"
          >
            <div class="menu-item">
              {{ $i18n.locale === 'fr' ? 'Projets' : 'Projects' }}
            </div>
          </v-tab>
          <v-tab
            href="#tab-2"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('contact', 'tab-2')"
          >
            <div>
              {{ $i18n.locale === 'fr' ? 'Contacts' : 'Contacts' }}
            </div>
          </v-tab>
          <v-tab
            href="#tab-3"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('activity', 'tab-3')"
          >
            <div>
              {{ $i18n.locale === 'fr' ? 'Activities' : 'Activities' }}
            </div>
          </v-tab>
          <v-tab
            href="#tab-4"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('note', 'tab-4')"
          >
            <div>
              {{ $i18n.locale === 'fr' ? 'Commentaires' : 'Comments' }}
            </div>
          </v-tab>
          <v-tab
            href="#tab-5"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('file', 'tab-5')"
          >
            <div>
              {{ $i18n.locale === 'fr' ? 'Fichiers' : 'Files' }}
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="loader-content" v-if="initLoading || getHistoryUsersLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="content-history-users-projet" v-else>
      <div
        class="scroll-list mt-3"
        v-if="getHistoryUsers && getHistoryUsers.length"
      >
        <v-list dense class="list-ensemble">
          <v-list-item-group class="list-ensemble-item">
            <v-list-item
              v-for="(history, index) in getHistoryUsers"
              :key="index"
              :value="index.id"
              class="custom-list-group custom-list-group-vue"
              :class="{
                'border-top-solid': index == 0
              }"
            >
              <v-list-item-icon class="img-center">
                <font-awesome-icon
                  :icon="['fas', 'circle-user']"
                  class="icon img-h-30 "
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="font-text font-sz-12 content">
                    <span
                      class="color-crm font-text font-sz-12 bold-600"
                      v-if="currentUser && currentUser.details"
                      >{{ currentUser.details.full_name }}</span
                    >
                    {{
                      ' ' +
                        $options.filters.typeHistory(
                          history.type,
                          $i18n.locale
                        ) +
                        ' ' +
                        $options.filters.HistoryEntity(
                          history.entity,
                          history.title_entity,
                          $i18n.locale
                        ) +
                        ' '
                    }}
                    <span
                      v-if="
                        history.projet_id &&
                          history.title_projet != '(supprimer)'
                      "
                      class="color-nom-projet"
                    >
                      <router-link
                        :to="'/projects/' + history.projet_id"
                        target="_blank"
                      >
                        <span class="color-nom-projet">
                          {{ history.title_projet }}</span
                        >
                      </router-link>
                    </span>
                    <span v-else>
                      <span class="color-crm font-sz-12 font-text">
                        {{ history.title_projet }}
                      </span>
                    </span>
                    <div v-if="history.key">
                      <div
                        class="comment-history"
                        v-if="
                          history.entity === 'pcomments' &&
                            history.type === 'PUT'
                        "
                      >
                        <div class="editor-comment-history mr-2">
                          <span>{{ history.previous }}</span>
                        </div>
                        →
                        <div class="editor-comment-history ml-2">
                          <span>{{ history.current }}</span>
                        </div>
                      </div>
                      <div v-else>
                        {{ $options.filters.HistoryName(history.name) }} :
                        {{ history.previous }} → {{ history.current }}
                      </div>
                    </div>

                    <div class="font-text font-sz-11  text-italic">
                      {{ history.formatDate }} {{ history.formatHeure }}
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="img-center">
                <font-awesome-icon
                  class="icon img-h-22 color-crm"
                  :icon="iconEntity(history.entity, history.type_activity)"
                />
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div
        class="scroll-list mt-3 div-aucun-list"
        v-if="!getHistoryUsers || !getHistoryUsers.length"
      >
        <div class="titre">
          {{
            $i18n.locale === 'fr'
              ? "Aucun Historiques de l'utilisateur"
              : 'None User history'
          }}
        </div>
      </div>
      <div class="footer-style-table">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            :total-visible="7"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            :label="
              $i18n.locale === 'fr' ? 'Eléments par page' : 'Rows per page'
            "
            outlined
            dense
            hide-details
            :no-data-text="
              $i18n.locale === 'fr' ? 'Aucun élément trouvé' : 'No items found'
            "
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      initLoading: true,
      page: 1,
      per_page: 10,
      entity_change: null,
      model: 'tab-0',
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ]
    }
  },

  methods: {
    ...mapActions(['fetchHistoryUsers', 'fetchSingleProject']),
    pagination(pagination) {
      this.page = pagination
      this.fetchHistoryUsers({
        page: this.page,
        per_page: this.per_page,
        user_id: this.currentUser.id,
        entity: this.entity_change
      })
    },
    changePerPage() {
      this.fetchHistoryUsers({
        page: this.page,
        per_page: this.per_page,
        user_id: this.currentUser.id,
        entity: this.entity_change
      })
    },
    async clickMenu(menu, tab) {
      this.selectedMenu = menu
      this.model = tab
      switch (menu) {
        case 'activity':
          this.entity_change = 'activities'
          this.model = 'tab-4'
          break
        case 'file':
          this.entity_change = 'files'
          this.model = 'tab-6'
          break
        case 'note':
          this.entity_change = 'pcomments'
          this.model = 'tab-5'
          break
        case 'projet':
          this.model = 'tab-1'
          this.entity_change = 'projets'
          break
        case 'contact':
          this.entity_change = 'contacts'
          this.model = 'tab-2'
          break
        case 'miseAjour':
          this.model = 'tab-0'
          this.entity_change = ''
          break
        default:
          this.entity_change = ''
          this.model = 'tab-0'
          break
      }
      this.page = 1
      await this.fetchHistoryUsers({
        page: this.page,
        per_page: this.per_page,
        user_id: this.currentUser.id,
        entity: this.entity_change
      })
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getHistoryUsersError',
      'getHistoryUsersLoading',
      'getHistoryUsers',
      'countPaginationHistoriqueUser'
    ]),
    totalPages() {
      if (this.countPaginationHistoriqueUser) {
        return Math.ceil(this.countPaginationHistoriqueUser / this.per_page)
      }
      return this.countPaginationHistoriqueUser
    },
    iconEntity() {
      return function(entity, type_activity) {
        if (entity && entity === 'contacts') {
          return ['fas', 'user-tie']
        } else if (entity && entity === 'files') {
          return ['fas', 'file-alt']
        } else if (entity && entity === 'activities') {
          if (type_activity === 'Pré visite') {
            return ['fas', 'briefcase']
          } else if (type_activity === 'Appel') {
            return ['fas', 'phone-alt']
          } else if (type_activity === 'Planning') {
            return ['fas', 'truck']
          } else {
            return ['fas', 'hourglass-start']
          }
        } else if (entity && entity === 'projets') {
          return ['fas', 'money-check']
        } else if (entity && entity === 'pcomments') {
          return ['fas', 'comment-alt']
        } else {
          return ['fas', 'money-check']
        }
      }
    }
  },

  filters: {
    typeHistory: function(value, translate) {
      switch (value) {
        case 'PUT':
          return translate === 'fr' ? 'a modifié' : 'a modified'
        case 'POST':
          return translate === 'fr' ? 'a ajouté' : 'added'
        case 'DELETE':
          return translate === 'fr' ? 'a supprimé' : 'deleted'
        default:
          return value
      }
    },
    HistoryEntity: function(entity, title, translate) {
      if (entity === 'projets') {
        return translate === 'fr' ? 'l’offre ' : 'the offer '
      } else if (entity === 'pcomments') {
        return translate === 'fr'
          ? 'commentaire au sujet de l’offre '
          : 'comments on the offer '
      } else if (entity === 'activities') {
        if (title) {
          return translate === 'fr'
            ? title + ' au sujet de l’offre '
            : title + ' about the offer '
        } else {
          return translate === 'fr'
            ? 'activity au sujet de l’offre '
            : 'activity about the offer '
        }
      } else if (entity === 'contacts') {
        if (title) {
          return translate === 'fr'
            ? title + ' au sujet de l’offre '
            : title + ' about the offer '
        } else {
          return translate === 'fr'
            ? 'contact au sujet de l’offre '
            : 'comments on the offer '
        }
      } else if (entity === 'files') {
        if (title) {
          return translate === 'fr'
            ? title + ' au sujet de l’offre '
            : title + ' about the offer '
        } else {
          return translate === 'fr'
            ? 'fichier au sujet de l’offre '
            : 'file about the offer '
        }
      }
    },
    HistoryName: function(value) {
      switch (value) {
        case 'reference_fiscal_avis':
          return 'Reference Fiscal Avis'
        case 'cp_reference_fiscal_avis':
          return 'Copie Reference Fiscal Avis'
        default:
          return value
      }
    }
  },
  async mounted() {
    this.initLoading = true
    this.fetchHistoryUsers({
      page: this.page,
      per_page: this.per_page,
      user_id: this.currentUser.id,
      entity: this.entity_change
    })
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.historyUsers-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .scroll-list {
    max-height: calc(100vh - 305px) !important;
    height: calc(100vh - 305px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    .v-list {
      padding: 0px 0px !important;
    }
  }
  .scroll-list::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .scroll-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .scroll-list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .content {
    line-height: 21px;
  }
}
</style>
<style lang="scss">
.historyUsers-setting {
  .comment-history {
    display: flex;
    .editor-comment-history {
      background-color: #f6f6f6;
      border: 1px solid #5c2dd3;
      color: #5c2dd3;
      padding: 0px 8px;
    }
  }
  .color-nom-projet {
    a {
      overflow: hidden;
      font-weight: 600;
      font-size: 11px;
      line-height: 14.07px;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none !important;
      color: #5c2dd3 !important;
      font-family: 'Montserrat', sans-serif;
    }
    &.router-link-exact-active {
      // &.router-link-active {
      color: #5c2dd3 !important;
      font-weight: 700;
      // }
    }
  }
}
</style>
